
import './index.css'
import { createNoise2D } from 'simplex-noise';
import { createNoise3D } from 'simplex-noise';
import {Pane} from 'tweakpane';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import * as THREE from 'three'

/**
 * Setup
 */
var paneIsShowing = true;
const noise2 = createNoise2D();
const noise3 = createNoise3D();
 const loader = document.getElementsByClassName('f-loader')[0]

 const mouse = {
    x: 0,
    y: 0,
    speedX: 0,
    speedY: 0
  }

  const starterVal = 40;
 const parameters = {
    rows: starterVal,
    columns: starterVal,
    grid: starterVal*starterVal,
    spread: 0.2,
    pressure: 0.4,
    stickiness: 0.97,
    colorSeparation: 0.03,
    staticStartup: true,
    channels: 4,
    breakPoint: 500,
    isSmallScreen: false,
    aspectRatio: 0,
    loaded: false,
    image: 0,
    expFalloff: true,
    noiseType: 0,
    noiseStrength: 1,
    maxRez: 1000
  }

const MAX_FALLOFF_STRENGTH = 1.5
const MAX_NOISES = 3
const MouseSpeed = require("mouse-speed")
const speed = new MouseSpeed()
speed.init(() => {
    mouse.speedX = speed.speedX
    mouse.speedY = speed.speedY
})

var pane;
const loadPane = () => {
    pane = new Pane({
        title: 'Distortions',
        expanded: true,
    })
    pane.addBinding(parameters, 'columns', {step: 1, min: 1, max: parameters.maxRez}).on('change', () =>{ 
        parameters.grid = parameters.rows * parameters.columns
        setDataTexture()
    })
    pane.addBinding(parameters, 'rows', {step: 1, min: 1, max: parameters.maxRez}).on('change', () =>{ 
        parameters.grid = parameters.rows * parameters.columns
        setDataTexture()
    })
    pane.addBinding(parameters, 'expFalloff', {label:'falloff'})
    pane.addBinding(parameters, 'noiseType', {
        options: {
            none: 0,
            scatter_brain: 1,
            jittery_joe: 2,
            dookie_bot: 3,
        },
    }).on('change', () => {
        noiseslider.hidden = parameters.noiseType == 0
    });
    const noiseslider = pane.addBinding(parameters, 'noiseStrength', {label: 'noise', step: 0.1, min: 0.1, max: 1})
    noiseslider.hidden = parameters.noiseType == 0
    pane.addBinding(parameters, 'spread', {step: 0.01, min: 0, max: 1}).on('change', () =>{
        distortPass.material.uniforms.uColorSepSpread.value = 1 - parameters.spread
    })
    pane.addBinding(parameters, 'pressure', {step: 0.01, min: 0, max: 1})
    pane.addBinding(parameters, 'stickiness', {step: 0.01, min: 0, max: 1})
    pane.addBinding(parameters, 'colorSeparation', {step: 0.01, min: 0, max: 1}).on('change', () =>{ 
        distortPass.material.uniforms.uColorSep.value = parameters.colorSeparation
    })
    pane.addBinding(parameters, 'staticStartup', {label:'static startup'}).on('change', () =>{ 
        setDataTexture()
    })
}

const canvas = document.querySelector('canvas.webgl')
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const loadingManager = new THREE.LoadingManager()
loadingManager.onError = function ( t ) {
	//this means we reached the end of the images and should start over
    parameters.image = -1
    clickCore()
};

var distortionSurface, dataTexture, imageTexture
const imageLoader = new THREE.TextureLoader(loadingManager)
const loadImage = () => {
    gsap.to(loader, {opacity: 1, duration: 0.25})
    gsap.to(canvas, {opacity: 0, duration: 0.4})
    window.setTimeout(()=>{
        imageLoader.load('assets/' + parameters.image + '.png',
        function(t) {
            gsap.to(loader, {opacity: 0, duration: 0.4})
            gsap.to(canvas, {opacity: 1, duration: 0.4, delay: 0.25})
            if(imageTexture) { imageTexture.dispose() }
            imageTexture = t
            parameters.aspectRatio = imageTexture.image.height/imageTexture.image.width
            buildScene()
        })
    }, 250)
}

if(paneIsShowing) { 
    document.getElementById('fx-toggle').innerHTML = 'hide fx panel'
    loadPane() 
}
loadImage()

const setDataTexture = () => {
    if(dataTexture){ dataTexture.dispose() }
    const data = new Float32Array(parameters.channels * parameters.grid);
    
    for (let i = 0; i < parameters.grid; i++) {
        for (let j =0;j < parameters.channels; j++) {
            data[i * 4 + j] = (parameters.staticStartup ? 0 : Math.random() * 255 - 125)
        }
    }
    dataTexture = new THREE.DataTexture(data, parameters.columns, parameters.rows, THREE.RGBAFormat, THREE.FloatType)
    distortionSurface.material.uniforms.uDataTexture.value = dataTexture
    distortPass.material.uniforms.uDataTexture.value = dataTexture
}

const updateDistortion = () => {
    let data = dataTexture.image.data
    if (!data) {return}
    for (let i = 0; i < data.length; i += parameters.channels) {
      data[i] *= parameters.stickiness
      data[i + 1] *= parameters.stickiness
    }

    const gridMouseX = parameters.columns * mouse.x;
    const gridMouseY = parameters.rows * mouse.y;
    const mappedSpread = remap(parameters.spread, 0, 1, 0.01, 0.5)
    const mappedPressure = remap(parameters.pressure, 0, 1, 0.01, 0.1)
    const maxDist = Math.max(parameters.columns, parameters.rows) * mappedSpread;
    const maxDistSq = maxDist ** 2

    for (let ii = 0; ii < parameters.columns; ii++) {
      for (let j = 0; j < parameters.rows; j++) {

        const distance = ((gridMouseX - ii) ** 2) / camera.aspect + (gridMouseY - j) ** 2

        if (distance < maxDistSq) {
          const index = parameters.channels * (ii + parameters.columns * j);
          const p = 1 - distance/maxDistSq
          var intensity = 1
          if(parameters.expFalloff) { intensity = remap(Math.pow(distance/maxDistSq, MAX_FALLOFF_STRENGTH), 0, maxDistSq, 0.1, 1) }
          var n = 1;
          if(parameters.noiseType == 1) {
            n = noise2(ii, j)
            //   n = noise2(gridMouseY/-p, gridMouseX/p)
            //   n = noise2(gridMouseY*-p, gridMouseX*p)
            //   n = noise2(p/gridMouseX, p/gridMouseY)
            n = n * parameters.noiseStrength
          }
          else if (parameters.noiseType == 2) {
            // n = noise3(ii, j, p)
          n = noise3(gridMouseX, gridMouseY, p)
        //   n = noise3(mouse.speedX, mouse.speedY, remap(Math.random(), 0, 1, -0.1, 0.1))
            n = n * parameters.noiseStrength
          }
          else if (parameters.noiseType == 3) {
            n = noise3(mouse.x, mouse.y, maxDistSq)
            // n = noise2(mouse.speedX/p, mouse.speedY/p)
        //   n = noise3(gridMouseX, gridMouseY, p)
        //   n = noise3(mouse.speedX, mouse.speedY, remap(Math.random(), 0, 1, -0.1, 0.1))
            n = n * parameters.noiseStrength
          }
          data[index] += mappedPressure * mouse.speedX * p * n * intensity;
          data[index + 1] -= mappedPressure * mouse.speedY * p * n * intensity;
        }
      }
    }

    dataTexture.needsUpdate = true
}

// Shaders
const vertexShader = /*glsl*/`
    varying vec2 vUv;

    void main()
    {
        // vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        // vec4 viewPosition = viewMatrix * modelPosition;
        // vec4 projectedPosition = projectionMatrix * viewPosition;
        // gl_Position = projectedPosition;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vUv = uv;
    }
`;

const fragmentShader = /*glsl*/`
    varying vec2 vUv;
    uniform sampler2D uDataTexture;
    uniform sampler2D uImageTexture;

    void main() {
        gl_FragColor = texture2D(uImageTexture, vUv);

        // debug 
        // vec4 offset = texture2D(uDataTexture,vUv); 
        // gl_FragColor = texture2D(uImageTexture,vUv - 0.8*offset.rg);
        // gl_FragColor = offset;
    }
`;

const buildScene = () => {
    if (distortionSurface) { scene.remove(distortionSurface) }
    distortionSurface = new THREE.Mesh(
        new THREE.PlaneGeometry(1,1 * parameters.aspectRatio,50,50),
        new THREE.ShaderMaterial({
          vertexShader: vertexShader,
          fragmentShader: fragmentShader,
          uniforms: {
            uDataTexture: {value: dataTexture},
            uImageTexture: {value: imageTexture}
          }
        })
      );
      scene.add(distortionSurface);

    setDataTexture()
    fitCameraToCenteredObject(camera, distortionSurface)
    if(!parameters.loaded){
        tick()
        parameters.loaded = true 
    }
}

const fitCameraToCenteredObject = function (camera, object) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject( object );

    var middle = new THREE.Vector3();
    var size = new THREE.Vector3();
    boundingBox.getSize(size);

    const fov = camera.fov * ( Math.PI / 180 );
    const fovh = 2*Math.atan(Math.tan(fov/2) * camera.aspect);
    let dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
    let dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
    let cameraZ = Math.max(dx, dy) * 1.2;

    camera.position.z = cameraZ
    // set the far plane of the camera so that it easily encompasses the whole object
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

    camera.far = cameraToFarEdge * 3;
    camera.updateProjectionMatrix();
}

const scene = new THREE.Scene()
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 1
scene.add(camera)

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0xFFFFFF)

const composer = new EffectComposer(renderer)
const renderPass = new RenderPass(scene, camera)
composer.addPass(renderPass)

const DistortShader = {
    uniforms: {
        uMouse: { value: new THREE.Vector2(-10,-10) },
        uColorSep: { value: parameters.colorSeparation },
        uColorSepSpread: { value: 1 - parameters.spread },
        uDataTexture: {value: dataTexture},
        tDiffuse: { value: null }
    },
    vertexShader: /*glsl*/`
        varying vec2 vUv;
        void main() 
        {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            vUv = uv;
        }
    `,
    fragmentShader: /*glsl*/`
        uniform vec2 uMouse;
        uniform float uColorSep;
        uniform float uColorSepSpread;
        uniform sampler2D tDiffuse;
        uniform sampler2D uDataTexture;
        varying vec2 vUv;
        //uv, offset, bias, strength
        float superbump(vec2 uv, vec2 o, vec2 b, float s) {
            return smoothstep(b.x, b.y, 1.0 - distance(uv, o)) * s;
        }
        void main() 
        {
            vec2 newUV = vUv;

            //by using uDataTexture here we can extend distortion beyond image boundary
            //like the color separation effect
            vec4 offset = texture2D(uDataTexture,vUv); 
            newUV -= 0.2*offset.rg;

            float s = superbump(newUV, uMouse, vec2(uColorSepSpread,1.0), 0.5);
            float r = pow(texture2D(tDiffuse, newUV += s * (uColorSep * -.4)).x, 3.);
            float g = pow(texture2D(tDiffuse, newUV += s * (uColorSep * .5)).y, 3.);
            float b = pow(texture2D(tDiffuse, newUV += s * (uColorSep * .6)).z, 3.);
		    vec4 color = vec4(r, g, b, 1.);
            gl_FragColor = color;

            // vec4 test = texture2D(tDiffuse,newUV); 
            // gl_FragColor = test;
        }
    `
}
const distortPass = new ShaderPass(DistortShader)
composer.addPass(distortPass)

const tick = () =>
{
    updateDistortion()
    composer.render()
    window.requestAnimationFrame(tick)
}

// Events
window.addEventListener('load', () => {
    document.getElementById('fx-toggle').addEventListener('click', (e) => {
        if(paneIsShowing) { 
            e.target.innerHTML = 'show fx panel'
            pane.dispose(); 
        }
        else { 
            e.target.innerHTML = 'hide fx panel'
            loadPane(); 
        }
        paneIsShowing = !paneIsShowing
    })
})

window.addEventListener('resize', () =>
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    setDataTexture()
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    fitCameraToCenteredObject(camera, distortionSurface)
})

window.addEventListener('pointermove', (e) => {
    mouse.x = e.clientX / sizes.width;
    mouse.y = 1 - (e.clientY / sizes.height);
    distortPass.uniforms.uMouse.value = new THREE.Vector2(mouse.x, mouse.y);
})

const clickCore = () => {
    parameters.image = parameters.image + 1
    randomizeParameters()
    loadImage()
}

canvas.addEventListener('click', () => {
    clickCore()
})

const randomizeParameters=()=>{
    const r = Math.max(1, parameters.maxRez * Math.random() | 0)
    const c = Math.max(1, parameters.maxRez * Math.random() | 0)
    parameters.rows = r
    parameters.columns = c
    parameters.grid = r * c
    parameters.spread = Math.random()
    parameters.pressure = Math.random()
    parameters.stickiness = Math.random()
    parameters.colorSeparation = Math.random() < 0.5 ? 0 : Math.random()
    parameters.expFalloff = Math.random() < 0.5
    parameters.noiseType = randomInteger(0,MAX_NOISES)
    parameters.noiseStrength = Math.random()
    pane.refresh();
}

const randomInteger=(min, max)=>{
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const remap = (value, vMin, vMax, mMin, mMax) => {
    return mMin + (mMax - mMin) * (value - vMin) / (vMax - vMin);
}
